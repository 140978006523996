import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import helpers from '../../helper/helpers';


//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Profile extends Component {
	constructor(props){
	
	  
       super(props);
       this.state = {
           
           errors: {},
            succMsg: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succClass: ['head_err_msg'],
           selectedFile: null,
           profile_img: '',
           selcted_social_network: [],
           fields: {},
           fieldsEmail:{},
           fieldsWeb:{},
           fieldsAdd:{},
           values:["1"],
           valuesEmail:["1"],
            valuesAdd:["1"],
           valuesWeb:["1"],
           fields_count:0,
           email_count:0,
           web_count:0,
           add_count:0,
           changeText:["Save"],
           disabled: false,
           
		}
		
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_contact_details();
		}
		
		
    	
		
		
    }
    
    
    
    createUI(){
    	
     	return this.state.values.map((el, i) => 
         	<div key={i}>
    	   	
    	   	 	<div class="row">
							<div class="field-hdng">
								<span class="left">Phone</span>
								<span class="right"><button onClick={this.removePhoneClick.bind(this, i,)} type="button" class="button css-gocinq" data-testid="delete-button">
									<span class="text">Delete</span> 
									<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="var(--chakra-colors-black)" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18">
									</line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></span>
							</div>
							<div class="field">
								<span class="select">
									<select  onChange={this.handleContactChange.bind(this, "contact_type_"+i)}  value= {this.state.fields["contact_type_"+i]}  name={`contact_type_${i}` } >
										<option value="Cell Phone">Cell Phone</option>
										<option value="SMS">SMS</option>
										<option value="Fax">Fax</option>
										<option value="Home">Home</option>
										<option value="Work">Work</option>
									</select>
								</span>
								<span class="input">
										<PhoneInput
										   
											inputProps={{
    											name: 'contact_'+i,
    											required: true,
    											autoFocus: true,
    											className:'phone_div',
    											
  											}}
  											country={''}
  											value= {this.state.fields["contact_"+i]}
  											enableAreaCodes={true}
  											onChange={(e)=>this.handleChange(e,"contact_"+i)}
  											
										/>
									
								</span>
								 
							</div>

						</div>
						
         	</div>          
     	)
  	}
  	
  	 createEmailUI(){
    	
     	return this.state.valuesEmail.map((el, i) => 
         	<div key={i}>
    	   	
    	   	 	<div class="field-hdng">
						<span class="left">Email</span>
						<span class="right">
							<button type="button" onClick={this.removeEmailClick.bind(this, i,)} class="button css-gocinq" data-testid="delete-button">
								<span class="text">Delete</span>
								<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="var(--chakra-colors-black)" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></span>
				</div>
				<div class="field">
						<span class="input">
								<input required name={`email_${i}` } value= {this.state.fieldsEmail["email_"+i]}  onChange={this.handleEmailChange.bind(this, "email_"+i)} class="form-control" placeholder="" type="email" />
						</span>
				</div> 
			</div>
     	)
  	}
  	
  	createWebUI(){
    	
     	return this.state.valuesWeb.map((el, i) => 
         	<div id="website" key={i}>
    	   	 	<div class="field-hdng">
					<span class="left">Website URL</span>
					<span class="right"><button onClick={this.removeWebClick.bind(this, i,)} type="button" class="button css-gocinq" data-testid="delete-button"><span class="text">Delete</span><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="var(--chakra-colors-black)" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></span>
				</div>
				<div class="field">
					<span class="input">
						<input required name={`website_${i}` } value= {this.state.fieldsWeb["website_"+i]}  onChange={this.handleWebChange.bind(this, "website_"+i)} class="form-control" placeholder="" type="text"/>
					</span>
				</div>
				<div className='invalid-feedback text-start d-block'>
						{this.state.errors["website_"+i]}  
					</div>
			</div>
     	)
  	}
  	
  	createAddUI(){
    	
     	return this.state.valuesAdd.map((el, i) => 
         	<div key={i}>
         		<div class="field-hdng">
						<span class="left">Address type</span>
								<span class="right"><button type="button" onClick={this.removeAddClick.bind(this, i,)} class="button css-gocinq" data-testid="delete-button"><span class="text">Delete</span><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="var(--chakra-colors-black)" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></span>
							</div>
							<div class="field">
								<span class="select">
									<select  required onChange={this.handleAddChange.bind(this, "address_type_"+i)} value= {this.state.fieldsAdd["address_type_"+i]}  name={`address_type_${i}` }>
										<option value="">Select Location</option>
										<option value="Location">Location</option>
										<option value="Office">Office</option>
										<option value="Store">Store</option>
									</select>
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">Street Line One</span>
							</div>
							<div class="field">
								<span class="input">
									<input required onChange={this.handleAddChange.bind(this, "area1_"+i)} value= {this.state.fieldsAdd["area1_"+i]}  name={`area1_${i}` } class="form-control" placeholder="" type="text" />
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">Street Line Two</span>
							</div>
							<div class="field">
								<span class="input">
									<input onChange={this.handleAddChange.bind(this, "area2_"+i)} value= {this.state.fieldsAdd["area2_"+i]}  name={`area2_${i}` } class="form-control" placeholder="" type="text" />
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">City</span>
							</div>
							<div class="field">
								<span class="input">
									<input required onChange={this.handleAddChange.bind(this, "city_"+i)} value= {this.state.fieldsAdd["city_"+i]}  name={`city_${i}` } class="form-control" placeholder="" type="text" />
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">State / Province</span>
							</div>
							<div class="field">
								<span class="input">
									<input required onChange={this.handleAddChange.bind(this, "state_"+i)} value= {this.state.fieldsAdd["state_"+i]}  name={`state_${i}` }  class="form-control" placeholder="" type="text" />
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">Postal Code</span>
							</div>
							<div class="field">
								<span class="input">
									<input required onChange={this.handleAddChange.bind(this, "pcode_"+i)} value= {this.state.fieldsAdd["pcode_"+i]}  name={`pcode_${i}` }class="form-control" placeholder="" type="text" />
								</span>
							</div>
							<div class="field-hdng">
								<span class="left">Country</span>
							</div>
							<div class="field">
								<span class="input">
									<input required onChange={this.handleAddChange.bind(this, "country_"+i)} value= {this.state.fieldsAdd["country_"+i]}  name={`country_${i}` }class="form-control" placeholder="" type="text" />
								</span>
							</div>
    	   	 	
			</div>
     	)
  	}
  	
//================= Phone number Code============================///    
addPhoneClick(){
    this.setState(prevState => ({ values: [...prevState.values, '']}))
    let fields_count=this.state.fields_count;
    fields_count=fields_count + 1;
    this.setState({ fields_count });
    
  }
  
removePhoneClick(i){
     let values = [...this.state.values];
     values.splice(i,1);
     this.setState({ values });
     let fields_count=this.state.fields_count;
     let fields = this.state.fields;
     let updated_fields={};
	 let idx=0;
	 for (var j = 0; j <= fields_count; j++) {
     	if(j!=i){
     		let key_1='contact_type_'+idx;
     		let val_1=this.state.fields["contact_type_"+j];
     		let key_2='contact_'+idx;
     		let val_2=this.state.fields["contact_"+j];
        	updated_fields[key_1] = val_1;
        	updated_fields[key_2] = val_2; 
        	idx++;
     	}
	 }
    fields_count=fields_count- 1;
    this.setState({ fields_count });
    fields = updated_fields;
	this.setState({fields});
	console.log(fields);
     
  }
  
 
  
  handleChange(e,field){  
   let fields = this.state.fields;
   fields[field] = e;        
   this.setState({fields});
        
	}
handleContactChange(field, e){  
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
    console.log(fields)
        
}
  
//================================================================///
  

//==================== Email code ===============================//  
addEmailClick(){
    this.setState(prevState => ({ valuesEmail: [...prevState.valuesEmail, '']}))
    let email_count=this.state.email_count;
    email_count=email_count + 1;
    this.setState({email_count});
    
}
removeEmailClick(i){
     let valuesEmail = [...this.state.valuesEmail];
     valuesEmail.splice(i,1);
     this.setState({ valuesEmail });
     let email_count=this.state.email_count;
     
     let fieldsEmail = this.state.fieldsEmail;
     let updated_fieldsEmail={};
	 let idx=0;
	 for (var j = 0; j <= email_count; j++) {
     	if(j!=i){
     		let key_1='email_'+idx;
     		let val_1=this.state.fieldsEmail["email_"+j];
        	updated_fieldsEmail[key_1] = val_1; 
        	idx++;
     	}
	 }
    email_count=email_count- 1;
    this.setState({ email_count });
    fieldsEmail = updated_fieldsEmail;
	this.setState({fieldsEmail});
	
  }
  
handleEmailChange(field, e){  
    let fieldsEmail = this.state.fieldsEmail;
    fieldsEmail[field] = e.target.value;        
    this.setState({fieldsEmail});
    console.log(fieldsEmail)
        
}
//========================================================//	

//==================== Website code ===============================//  
addWebClick(){
    this.setState(prevState => ({ valuesWeb: [...prevState.valuesWeb, '']}))
    let web_count=this.state.web_count;
    web_count=web_count + 1;
    this.setState({web_count});
    
}
removeWebClick(i){
     let valuesWeb = [...this.state.valuesWeb];
     valuesWeb.splice(i,1);
     this.setState({ valuesWeb });
     let web_count=this.state.web_count;
     
     let fieldsWeb = this.state.web_count;
     let updated_fieldsWeb={};
	 let idx=0;
	 for (var j = 0; j <= web_count; j++) {
     	if(j!=i){
     		let key_1='website_'+idx;
     		let val_1=this.state.fieldsWeb["website_"+j];
        	updated_fieldsWeb[key_1] = val_1; 
        	idx++;
     	}
	 }
    web_count=web_count- 1;
    this.setState({ web_count });
    fieldsWeb = updated_fieldsWeb;
	this.setState({fieldsWeb});
	
  }
  
handleWebChange(field, e){  
    let fieldsWeb = this.state.fieldsWeb;
    fieldsWeb[field] = e.target.value;        
    this.setState({fieldsWeb});
    console.log(fieldsWeb)
        
}
//========================================================//	


//==================== Address code ===============================//  
addAddClick(){
    this.setState(prevState => ({ valuesAdd: [...prevState.valuesAdd, '']}))
    let add_count=this.state.add_count;
    add_count=add_count + 1;
    this.setState({add_count});
    
}
removeAddClick(i){
     let valuesAdd = [...this.state.valuesAdd];
     valuesAdd.splice(i,1);
     this.setState({ valuesAdd });
     let add_count=this.state.add_count;
     
     let fieldsAdd = this.state.add_count;
     let updated_fieldsAdd={};
	 let idx=0;
	 for (var j = 0; j <= add_count; j++) {
     	if(j!=i){
     		let key_1='address_type_'+idx;
     		let val_1=this.state.fieldsAdd["address_type_"+j];
     		let key_2='city_'+idx;
     		let val_2=this.state.fieldsAdd["city_"+j];
     		let key_3='state_'+idx;
     		let val_3=this.state.fieldsAdd["state_"+j];
     		let key_4='country_'+idx;
     		let val_4=this.state.fieldsAdd["country_"+j];
     		let key_5='area1_'+idx;
     		let val_5=this.state.fieldsAdd["area1_"+j];
     		let key_6='area2_'+idx;
     		let val_6=this.state.fieldsAdd["area2_"+j];
     		let key_7='pcode_'+idx;
     		let val_7=this.state.fieldsAdd["pcode_"+j];
        	updated_fieldsAdd[key_1] = val_1; 
        	updated_fieldsAdd[key_2] = val_2; 
        	updated_fieldsAdd[key_3] = val_3; 
        	updated_fieldsAdd[key_4] = val_4; 
        	updated_fieldsAdd[key_5] = val_5; 
        	updated_fieldsAdd[key_6] = val_6; 
        	updated_fieldsAdd[key_7] = val_7; 
        	idx++;
     	}
	 }
    add_count=add_count- 1;
    this.setState({ add_count });
    fieldsAdd = updated_fieldsAdd;
	this.setState({fieldsAdd});
	
  }
  
handleAddChange(field, e){  
    let fieldsAdd = this.state.fieldsAdd;
    fieldsAdd[field] = e.target.value;        
    this.setState({fieldsAdd});
        
}
//========================================================//	


get_contact_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let type = 'get-contactinfo' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
			
				//======== Get Phone Data ============//
				let fields = JSON.parse(res.data.phone_data);
				let values = [];
				let fields_count = res.data.phone_count;
				if(fields_count>=0){
				for(let i=0; i< fields_count;i++){
					values.push(i);
				}
				}
				this.setState({fields});
				this.setState({values});
				fields_count=parseInt(fields_count) - 1;
				this.setState({fields_count});
				
				//======== Get Email Data ============//
				let fieldsEmail = JSON.parse(res.data.email_data);
				let valuesEmail = [];
				let email_count = res.data.email_count;
				if(email_count>=0){
				for(let i=0; i< email_count;i++){
					valuesEmail.push(i);
				}
				}
				this.setState({fieldsEmail});
				this.setState({valuesEmail});
				email_count=parseInt(email_count) - 1;
				this.setState({email_count});
				
				//======== Get Website Data ============//
				let fieldsWeb = JSON.parse(res.data.website_data);
				let valuesWeb = [];
				let web_count = res.data.website_count;
				if(web_count>=0){
				for(let i=0; i< web_count;i++){
					valuesWeb.push(i);
				}
				}
				this.setState({fieldsWeb});
				this.setState({valuesWeb});
				web_count=parseInt(web_count) - 1;
				this.setState({web_count});
				
				
				//======== Get Location Data ============//
				let fieldsAdd = JSON.parse(res.data.location_data);
				let valuesAdd = [];
				let add_count = res.data.location_count;
				if(add_count>=0){
				for(let i=0; i< add_count;i++){
					valuesAdd.push(i);
				}
				}
				this.setState({fieldsAdd});
				this.setState({valuesAdd});
				add_count=parseInt(add_count) - 1;
				this.setState({add_count});
				
				
				
			}
		})
		return false;
	}
urlPatternValidation = URL => {
	const regex = new RegExp('(https?://)');    
	return regex.test(URL);
};
handleValidation(e){
		
		
        let fields = this.state.fields;
        let fieldsWeb = this.state.fieldsWeb;
        let errors = {};
        let formIsValid = true;
        let web_count=this.state.web_count;
        for (var j = 0; j <= web_count; j++) {
	     	const isTrueVal = !fieldsWeb["website_"+j] || this.urlPatternValidation(fieldsWeb["website_"+j]);
		    if(isTrueVal == false){
		    	formIsValid = false;
	           	errors["website_"+j] = "Please add valid url with https";
	           	window.location.hash = '#website';
		    }

		 }
       this.setState({errors: errors});
       
       return formIsValid;
	}

	saveContact(e) {
	
		let changeText = this.state.changeText;
		changeText = 'Please wait...';
		this.setState({ changeText,disabled:true })
		let fields = JSON.stringify(this.state.fields);
		let fields_count=this.state.fields_count;
		fields_count=parseInt(fields_count) +1;
		
		let fieldsEmail = JSON.stringify(this.state.fieldsEmail);
		let email_count=this.state.email_count;
		email_count=parseInt(email_count) +1;
		
		let fieldsWeb = JSON.stringify(this.state.fieldsWeb);
		let web_count=this.state.web_count;
		web_count=parseInt(web_count) +1;
		
		let fieldsAdd = JSON.stringify(this.state.fieldsAdd);
		let add_count=this.state.add_count;
		add_count=parseInt(add_count) +1;
		
		let type = 'update-contactinfo' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		const params = {
		   "phone_data":fields,
		   "phone_count":fields_count,
		   "email_data":fieldsEmail,
		   "email_count":email_count,
		   "website_data":fieldsWeb,
		   "website_count":web_count,
		   "location_data":fieldsAdd,
		   "location_count":add_count,
		   "user_id":user_id,
		};
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : JSON.stringify( params )
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	 	this.setState({ succClass })
         	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
           		
			}
		})
	}
	
contactSubmit(e){
        e.preventDefault();
       
        if(this.handleValidation(e)){
        	 const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	         this.saveContact(e);
	    }else{
	      const dynamiclass = 'alert alert-danger is_error';
          this.setState({ dynamiclass });
          let errors2=this.state.errors;
	    }

    }
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div class="profile-screen">
	<div class="container">
		<header class="header">
			<div class="row">
				<div class="menu col-sm-12">
					<div class="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div class="main-content">
			<div class="col-xs-12 name-hrdr">
				<h2>Edit contact info<span class="orange">.</span></h2>
			</div>
			
				<form method="POST" onSubmit= {this.contactSubmit.bind(this)} >
			
				<div class="infob-section social-edit-sec">
				
					<div className={this.state.succClass}>
					<ul>       
		           	 <li> {this.state.succMsg["msg"]} </li>
		                     
	            	</ul>
            		</div> 
					
					<div class="form">
						{this.createUI()}   
						<div class="add-new" onClick={this.addPhoneClick.bind(this)} data-testid="add-social-account-button"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="inherit" height="16" width="16" xmlns="http://www.w3.org/2000/svg" ><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg><span class="text"> Add phone</span></div>
					</div>
					<div class="form pdng">
						<div class="row">
							<h4>Email</h4>
							{this.createEmailUI()}
							
						</div>
						<div onClick={this.addEmailClick.bind(this)} class="add-new" data-testid="add-social-account-button"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="inherit" height="16" width="16" xmlns="http://www.w3.org/2000/svg" ><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg><span class="text"> Add email</span></div>
					</div>
					<div class="form pdng">
						<div class="row">
							
							<h4>Website</h4>
							{this.createWebUI()}
							
						</div>
						<div class="add-new" onClick={this.addWebClick.bind(this)} data-testid="add-social-account-button"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="inherit" height="16" width="16" xmlns="http://www.w3.org/2000/svg" ><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg><span class="text"> Add website</span></div>
					</div>
					<div class="form wdth">
						<div class="row">
							<h4>Address</h4>
							{this.createAddUI()}
							
						</div>
						<div class="add-new" onClick={this.addAddClick.bind(this)} data-testid="add-social-account-button"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="inherit" height="16" width="16" xmlns="http://www.w3.org/2000/svg" ><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg><span class="text"> Add location</span></div>
					</div>
					<div class="button-sec">
						<div class="action">
							<button type="submit" class="stap-button" data-testid="profile-connections-button" disabled={this.state.disabled}>{this.state.changeText}</button>
						</div>
					</div>
				</div>
				</form>
		</div>
		
	</div>
</div>

    );
  }
}

export default Edit_Profile;
