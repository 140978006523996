import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           all_profile:[],
           succMsg: {},
    	   succClass: ['head_err_msg'],
    	   changeText:["Create new profile"],
           disabled: false,
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_all_profile();
		}
		
		
		
    }
    
    get_all_profile(){
		let data = this.state.data;
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let type = 'all-profile' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == true) {
				let all_profile = this.state.all_profile;
				all_profile = res.data
				 this.setState({all_profile:res.data});
			}
		})
		return false;
	}

    handleValidation(e){		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["profile_link"]){
           formIsValid = false;
           errors["profile_link"] = "Profile link cannot be empty!";
        }
        this.setState({errors: errors});
        return formIsValid;
	}

	profile(e) {	
		let changeText = this.state.changeText;
		changeText = 'Please wait...';
		this.setState({ changeText,disabled:true })
		let fields = this.state.fields;
		let type = 'add-profile' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "profile_link="+fields.profile_link+"&user_id="+user_id+"&action=add"
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			    this.get_user_details();
			    setTimeout(() => {
				  this.props.history.push('/edit-profile')
				}, 2000);
			}else {
				let errors={};
				errors['profile_link']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
	}

	get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let innerColor = this.state.innerColor;
		let outerColor = this.state.outerColor;
		let type = 'profile-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			if (res.status == 'True') {
			let login_session =JSON.parse(window.sessionStorage.getItem('login_session'));
			 login_session["first_name"]= res.data.profiledata.first_name;
			 login_session["last_name"]= res.data.profiledata.last_name;
			 login_session["job_title"]= res.data.profiledata.job_title;
			 login_session["company"]= res.data.profiledata.company;
			 login_session["profile_pic"]= res.data.profiledata.profile_pic;
			 login_session["bio"]= res.data.profiledata.bio;
			 login_session["outerColor"]= outerColor;
			 login_session["innerColor"]= innerColor;
			 login_session["outerColor"]= outerColor;
			 window.sessionStorage.setItem('login_session', JSON.stringify(login_session));	 
			}
		})
		return false;
	}
	
	profileSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.profile(e);
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value; 
        this.setState({fields});
    }
	
	
	
	
  render() {
	let all_profiles = this.state.all_profile.length > 0
		&& this.state.all_profile.map((item, i) => {
		return (
				<div className={item.innerColor}>
					<div className="col-md-6 profile-pic">
						<img src={item.profile_pic}/>
					</div>
					<div className="rol-sec col-md-6">
						<div className="live_btn"><a href="">Live</a></div>
						<h1>{item.first_name} {item.last_name}</h1>
						<p className="rol">{item.job_title} at {item.company}</p>
					</div>
				</div>	
		)
	}, this);
	let change_profile = this.state.all_profile.length > 0
		&& this.state.all_profile.map((item, i) => {
		return (
				<option>{item.profile_link}</option>
		)
	}, this);
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/manage-profiles"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Customize your profile link</h2>			
				<div className="infof-section">
					<form method="post" onSubmit= {this.profileSubmit.bind(this)}>
						<div className={this.state.succClass}>
						<ul>       
				            <li> {this.state.succMsg["msg"]} </li>
				                     
			            </ul>
		            </div>
					<div className="change-profile form">
						<h3 className="sec-hdng">Profile Link</h3>
						<span className="label">Your profile URL</span>
						<input type="text" 
							value= {this.state.fields['profile_link']} 
							onChange={this.handleChange.bind(this, "profile_link")}
							name="profile_link"/>
						<p>https://app.smarttap.world/{this.state.fields['profile_link']}</p>
							<div className='invalid-feedback text-start d-block'>
                             {this.state.errors["profile_link"]}         
                        </div>
					</div>	
					<div className="button-sec">
					<div className="action">
						<button type="submit" className="stap-button">{this.state.changeText}</button>
					</div>
				</div>
				</form>
				</div>
			</div>
		</div>
		
	</div>
</div>


    );
  }
}

export default Edit_Bio;
