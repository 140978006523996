import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succMsg: {},
    	   succClass: ['head_err_msg'],
    	   changeText:["Save"],
           disabled: false,
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_user_details();
		}
		
		
		
    }
    
    get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let type = 'get-user-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True' && res.data.bio !== null) {
				 fields['bio'] = res.data.bio;  
				 fields["count"] = res.data.bio.length;
				 this.setState({fields});
				
				
			}else {
				fields["count"] = "0";
				this.setState({fields});
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
		return false;
	}

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Bio
        if(!fields["bio"]){
           formIsValid = false;
           errors["bio"] = "Bio cannot be empty!";
        }

       this.setState({errors: errors});
       return formIsValid;
	}

	bio(e) {
		
		let changeText = this.state.changeText;
		changeText = 'Please wait...';
		this.setState({ changeText,disabled:true })
		let fields = this.state.fields;
		let type = 'edit-profile' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id;
		const params = {
		   "bio":fields.bio,
		   "user_id":user_id
		};
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : JSON.stringify( params )
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			    setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
	bioSubmit(e){
        e.preventDefault();
        if(this.handleValidation(e)){
        	const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	        this.bio(e);
	    }else{
	      //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
	    }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
         
        let count  = e.target.value.length  
        fields["count"] = count; 
        if (count< 250) {
        	fields[field] = e.target.value; 
        }  
        this.setState({fields});
    }
	
	
	
	
  render() {
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Edit about<span className="orange">.</span></h2>
			</div>
			
				<div className="infob-section">
					<form method="post" onSubmit= {this.bioSubmit.bind(this)}>
						<div className={this.state.succClass}>
						<ul>       
				            <li> {this.state.succMsg["msg"]} </li>
				                     
			            </ul>
		            </div>
					<div className="form">
						<div className="field-hdng">
							<span className="left">Bio</span>
							<span className="right">{this.state.fields.count} / 250</span>
						</div>
						<div className="bio-field">
							<textarea rows="4" cols="50" name="bio" onChange={this.handleChange.bind(this, "bio")} value={this.state.fields['bio']}>
							
							</textarea>
							<div className='invalid-feedback text-start d-block'>
                             	{this.state.errors["bio"]}         
                        	</div>
						</div>
						
					</div>
					<div className="button-sec">
					<div className="action">
						<button type="submit" disabled={this.state.disabled} className="stap-button" data-testid="profile-connections-button">{this.state.changeText}</button>
					</div>
				</div>
				</form>
				</div>
		</div>
		
	</div>
</div>


    );
  }
}

export default Edit_Bio;
