import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import logo from '../../assets/images/logo.svg';
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';


class Login extends Component {
    constructor(props){
    
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
        }
        
        
    }

    handleValidation(e){
        
        
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //Email
        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Email id cannot be empty!";
        }
        
        if(e.target.email.value!=''){
           let lastAtPos = fields["email"].lastIndexOf('@');
           let lastDotPos = fields["email"].lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
              formIsValid = false;
              errors["email"] = "Email is not valid!";
            }
        }  

       this.setState({errors: errors});
       return formIsValid;
    }

    login(e) {
        
        let fields = this.state.fields;
        let type = 'forgot-password' ;
        let LOGIN_API = API_BASE.concat(type);
        fetch(LOGIN_API, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', 
            }),
            body : "email="+fields.email.trim() 
            })
            .then((result) => {
              return result.json();
        }).then((res) => {
            
            if (res.status == 'True') {
                alert(res.msg)
                this.props.history.push('/auth/login')
            }else {
                let errors={};
                errors['err']=res.msg;
                this.setState({errors: errors});
                const dynamiclass = 'alert alert-danger is_error';
                this.setState({ dynamiclass });
            window.scrollTo({
                  top: 0, 
                  behavior: 'smooth'
                });
                
            }
        })
    }
    
    contactSubmit(e){

        e.preventDefault();
        if(this.handleValidation(e)){
            const dynamiclass = 'head_err_msg';
             this.setState({ dynamiclass })
            this.login(e);
        }else{
          //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
        }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    
    
    
    
  render() {
    
        
    
    const getYear = new Date().getFullYear();
    return (
        <div className="login-screen">
    <div className="container">
        <header className="header">
            <figure><img src={logo} alt="logo" /></figure>
        </header>
        <div className="main-content forgot-password">
            <h2>Forgot Password</h2>
            <p>Enter your email and you will get instructions to reset your password</p>
            <div className="login-sec">
                <form method="POST" onSubmit= {this.contactSubmit.bind(this)} >
                
                    <div className={this.state.dynamiclass}>
                        <ul>       
                             <li>  {this.state.errors["err"]}   </li>
                             
                        </ul>
                    </div> 
                    <div role="group" className="stap-form-control">
                        
                        <div className="input__group">
                            <input type="email"
                                        className={`stap-input form-control`}
                                        id="email"
                                        name="email"
                                        onChange={this.handleChange.bind(this, "email")}
                                        value= {this.state.fields['email']}
                                        placeholder="Email"
                                        
                                    />
                            
                        </div>
                         <div className='invalid-feedback text-start d-block'>
                             {this.state.errors["email"]}         
                        </div>
                    </div>
                    <div className="submit-btn"><button type="submit" className="button" data-testid="login-button">Send Email</button></div>
                </form>
                <div className="noaccount"><Link className="signup-button css-1m1uuvi" to="/auth/login">Login</Link></div>
             </div>
        </div>
        
    </div>
</div>
    );
  }
}

export default Login;
