import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import logo from '../../assets/images/logo.svg';
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';


class Login extends Component {
    constructor(props){
    
       super(props);
        const values = window.location.href;
				const url = values.split('/'); 
				const name = url[4];
				this.state = {
           profile_name: name,
        }
        
        
    }

   
    
    
    
    
  render() {
    
        
    
    const getYear = new Date().getFullYear();
    return (
        <div className="login-screen">
    <div className="container">
        <div className="main-content no-account">
            <p>We couldn't find profile with {this.state.profile_name} handle</p>
        </div>
        
    </div>
</div>
    );
  }
}

export default Login;
