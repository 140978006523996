import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           selectedImage: {},
           setSelectedImage: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succMsg: {},
    	   	 succClass: ['head_err_msg'],
    	   	 changeText:["Update Password"],
           disabled: false,

		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}

		
		
    }
    
   handleValidation(e){
    
    
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["currentpassword"]){
           formIsValid = false;
           errors["currentpassword"] = "Current password cannot be empty!";
        }
         if(!fields["newpassword"]){
           formIsValid = false;
           errors["newpassword"] = "New password cannot be empty!";
        }
        if(fields["newpassword"] != fields["cpassword"]){
           formIsValid = false;
           errors["cpassword"] = "New password and Confirm password are not matched";
        }
        if(!fields["cpassword"]){
           formIsValid = false;
           errors["cpassword"] = "Confirm password cannot be empty!";
        }
       
	    

       this.setState({errors: errors});
       return formIsValid;
  }

  changePass(e) {
    
    let changeText = this.state.changeText;
		changeText = 'Please wait...';
		this.setState({ changeText,disabled:true })
    let fields = this.state.fields;
    let type = 'update-password' ;
    let LOGIN_API = API_BASE.concat(type);
      let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
    let user_id=sess_data.user_id;
    
    fetch(LOGIN_API, {
      method: 'POST',
      headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded', 
      }),
      body : "currentpassword="+fields.currentpassword+"&user_id="+user_id+"&newpassword="+fields.newpassword
      })
      .then((result) => {
        return result.json();
    }).then((res) => {
      this.setState({ changeText: "Update Password",disabled:false })
      if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	const fields = '';
    		this.setState({ fields })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
				setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
      }else {
        let errors={};
        errors['err']=res.msg;
        this.setState({errors: errors});
        const dynamiclass = 'alert alert-danger is_error';
        this.setState({ dynamiclass });
        window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
				});
              
      }
    })
  }


  
  changePassword(e){
    const dynamiclass = 'head_err_msg';
    this.setState({ dynamiclass })
    const succClass = 'head_err_msg';
    this.setState({ succClass })
    e.preventDefault();
      if(this.handleValidation(e)){
          const dynamiclass = 'head_err_msg';
           this.setState({ dynamiclass })
          this.changePass(e);
      }else{
        //const dynamiclass = 'alert alert-danger is_error';
          //this.setState({ dynamiclass });
      }

    }

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    
	
	
	
  render() {
    return (
    	      <div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Change Password<span className="orange">.</span></h2>
			</div>
				<form method="post" onSubmit= {this.changePassword.bind(this)}>
				 <div className={this.state.succClass}>
					<ul>       
			            <li> {this.state.succMsg["msg"]} </li>
			                     
		            </ul>
	        </div>
	        <div className={this.state.dynamiclass}>
						<ul>       
		            <li> {this.state.errors["err"]} </li>           
	           </ul>
          </div> 
				<div className="infof-section add-link-sec">
					
					<div className="form">
						<div className="field-sec">
							<input type="password" name="currentpassword" placeholder="Current Password" onChange={this.handleChange.bind(this, "currentpassword")} value={this.state.fields['currentpassword']}/>
							<div className='invalid-feedback text-start d-block'>
								{this.state.errors["currentpassword"]}  
							</div>
						</div>
						<div className="field-sec">
							<input type="password" minlength="8" name="newpassword" placeholder="New Password" onChange={this.handleChange.bind(this, "newpassword")} value={this.state.fields['newpassword']}/>
							<div className='invalid-feedback text-start d-block'>
								{this.state.errors["newpassword"]}  
							</div>
						</div>
						<div className="field-sec">
							<input type="text" minlength="8" name="cpassword" placeholder="Confirm Password" onChange={this.handleChange.bind(this, "cpassword")} value={this.state.fields['cpassword']}/>
							<div className='invalid-feedback text-start d-block'>
								{this.state.errors["cpassword"]}  
							</div>
						</div>
						
					</div>
					<div className="button-sec">
					<div className="action">
						<button type="submit" className="stap-button" disabled={this.state.disabled} data-testid="profile-connections-button">{this.state.changeText}</button>
					</div>
				</div>
				
			</div>
			</form>
		</div>
		
	</div>
</div>


    );
  }
}

export default Edit_Bio;
