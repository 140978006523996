import fetch from 'isomorphic-fetch';
import {reactLocalStorage} from 'reactjs-localstorage';
import API_BASE from '../components/constant';
const helpers = {
    check_login: function(){
		
		let isLoggedIn=reactLocalStorage.get('isLoggedIn');	
		if( isLoggedIn == 1 ) {
			let sess_val = JSON.parse(window.sessionStorage.getItem('login_session'));
			if(sess_val){
				if(sess_val.isLogged=='1'){
					return true;
				}
				else{
					return false;
				}
			}else{
				return false;
			}
			
		}
		else{
			return false;
		}
		
    },

    
	
	

	
}
export default helpers;