import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';
import { confirmAlert } from 'react-confirm-alert';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           all_profile:[],
           profile_link:[],
           succMsg: {},
    	   succClass: ['head_err_msg'],
    	   changeText:["Save"],
           disabled: false,
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_all_profile();
		}
		
		
		
    }
    
    get_all_profile(){
		let data = this.state.data;
		let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let type = 'all-profile' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == true) {
				let all_profile = this.state.all_profile;
				all_profile = res.data
				 this.setState({all_profile:res.data});
			}
		})
		return false;
	}

	changeProfileLink(e) {
		let fields = this.state.fields;
		let type = 'add-profile' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "profile_link="+fields.profile_link+"&user_id="+user_id+"&action=update-link"
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			    this.get_user_details();
			    setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	get_user_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let innerColor = this.state.innerColor;
		let outerColor = this.state.outerColor;
		let type = 'profile-data' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			if (res.status == 'True') {
			let login_session =JSON.parse(window.sessionStorage.getItem('login_session'));
			 login_session["first_name"]= res.data.profiledata.first_name;
			 login_session["last_name"]= res.data.profiledata.last_name;
			 login_session["job_title"]= res.data.profiledata.job_title;
			 login_session["company"]= res.data.profiledata.company;
			 login_session["profile_pic"]= res.data.profiledata.profile_pic;
			 login_session["bio"]= res.data.profiledata.bio;
			 login_session["outerColor"]= outerColor;
			 login_session["innerColor"]= innerColor;
			 login_session["outerColor"]= outerColor;
			 window.sessionStorage.setItem('login_session', JSON.stringify(login_session));	 
			}
		})
		return false;
	}

	comfirm_delete = (profile_link) => {
		
		confirmAlert({
		  //title: 'Confirm to submit',
		  message: 'Are you sure you want to delete this profile?',
		  buttons: [
			{
				
			  label: 'Yes',
			  onClick: () => this.deleteProfile(profile_link)
			},
			{
			  label: 'No',
			  //onClick: () => 
			}
		  ]
		})
	  };
	deleteProfile(profile_link){   
        let type = 'add-profile' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "profile_link="+profile_link+"&user_id="+user_id+"&action=delete-profile"
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
		 	 	this.setState({ succClass })
		 	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			    this.get_user_details();
			    setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
			}
		})
    }

    handleChange(field, e){
        let fields = this.state.fields; 
        if(field == 'profile_link'){
        	fields["profile_link"] = e.target.value; 
        }else{
        	fields["profile_link"] = field;
        }
        this.setState({fields});
        this.changeProfileLink(e);
    }
	
	
	
	
  render() {
	let all_profiles = this.state.all_profile.length > 0
		&& this.state.all_profile.map((item, i) => {
		let profile_link = this.state.profile_link;
		if(item.active == 'yes' && profile_link == ''){
			profile_link = item.profile_link; 
        	this.setState({profile_link});
		}
		return (
				<div className={item.innerColor}>
					<div className="col-md-6 profile-pic">
						<img src={item.profile_pic}/>
					</div>
					<div className="rol-sec col-md-6" onClick={this.handleChange.bind(this, item.profile_link)}>
						{item.active == 'yes'?(
							<div className="live_btn"><a href="">Live</a></div>
						):(
							<div className="live_btn activate" onClick={this.handleChange.bind(this, item.profile_link)}><a href="#">Activate</a></div>
						)}
						{item.active != 'yes' && item.delete == "yes" ?(
							<div className="delete-profile" onClick={this.comfirm_delete.bind(this,item.profile_link)}>
								<img src="https://smarttap.world/wp-content/themes/elementor-child/images/bin.png"/>
							</div>
						):(
							<></>
						)}
						<h1>{item.first_name} {item.last_name}</h1>
						{item.job_title ? (
							<p className="rol">{item.job_title} at {item.company}</p>
						):(
							<p className="rol"></p>
						)}
						
					</div>
				</div>	
		)
	}, this);
	let change_profile = this.state.all_profile.length > 0
		&& this.state.all_profile.map((item, i) => {
		return (
				<option>{item.profile_link}</option>
		)
	}, this);
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Manage Profiles<span className="orange">.</span></h2>
			</div>
			
				<div className="infob-section">
					<form method="post">
						<div className={this.state.succClass}>
						<ul>       
				            <li> {this.state.succMsg["msg"]} </li>
				                     
			            </ul>
		            </div>
					<div className="change-profile">
						<labal>Change your profile</labal>
						<select name="profile_link" value={this.state.profile_link} onChange={this.handleChange.bind(this, "profile_link")}>
							{change_profile}
						</select>
						<p>This profile is active now.</p>
					</div>	
					<div id="profile2" className="all-profile profile-sec">
						{all_profiles}						
					</div>
					<div className="button-sec">
					{this.state.all_profile.length < 3 && (
					<div className="action">
						<Link to="/setup-profiles"><button type="submit" className="stap-button">Create new profile</button></Link>
					</div>
					)}
				</div>
				</form>
				</div>
		</div>
		
	</div>
</div>


    );
  }
}

export default Edit_Bio;
